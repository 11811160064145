import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import dayjs from "dayjs"
import { display, timeDiff } from "../utils"
const loadText = require("src/utils").loadText
const eventText = loadText("event")
import { path } from "ramda"
import SEO from "../components/SEO"
import "../styles/event.less"
export const getStatus = (
  startTime: string,
  endTime: string,
  webinar_link: string
) => {
  if (webinar_link) {
    return "webinar"
  }
  if (!endTime) {
    return "ongoing"
  }
  if (dayjs(startTime).isAfter(dayjs())) {
    return "upcoming"
  } else {
    if (dayjs(endTime).isAfter(dayjs())) {
      return "ongoing"
    } else {
      return "finished"
    }
  }
}
export const getHeader = ({ status, startTime, endTime, address }) => {
  let res = ""
  const s = dayjs(startTime)
  const e = dayjs(endTime)
  if (!endTime) {
    return `${eventText.subject.date}: ${s.format("MMM D, YYYY")} | ${address}`
  }
  if (s.year() !== e.year()) {
    res = `${s.format("MMM D, YYYY")}-${e.format("MMM D, YYYY")}`
  }
  if (s.month() !== e.month()) {
    res = `${s.format("MMM D")}-${e.format("MMM D, YYYY")}`
  }

  res = `${s.format("MMM D")}${e.format(
    `${e.day() !== s.day() ? "-D" : ""}, YYYY`
  )}`

  // if (status === "upcoming") {
  //   return `${display(startTime)} | ${address}`
  // }
  // if (status === "finished") {
  //   return `${eventText.subject.date}: ${display(startTime)} | ${
  //     eventText.subject.duration
  //   }: ${timeDiff(startTime, endTime)}`
  // }
  return `${eventText.subject.date}: ${res} | ${address}`
}
export default function ({ pageContext }) {
  const {
    name,
    content,
    startTime,
    endTime,
    webinar_link,
    address,
    thumbnail,
    excerpt,
  } = pageContext
  const status = getStatus(startTime, endTime, webinar_link)

  return (
    <div className="event-article">
      <SEO
        // title={name}
        title={`${name} - ${eventText.subject.event} | 上海层峰`}
        featuredImage={path(["localFile", "publicURL"], thumbnail)}
        description={excerpt}
        // keywords={_tags.map(({ name }) => name)}
      />
      <Header logo="/logo-blue.png" />
      <div className="event-article-content page-middle">
        <div className="event-article-header">
          <h1
            className="font-36 semi-bold t-color"
            dangerouslySetInnerHTML={{ __html: name }}
          />
          <div className="font-20 semi-bold mt-24">
            {getHeader({ status, startTime, endTime, address })}
          </div>
        </div>
        {webinar_link && (
          <>
            <iframe
              width="900"
              height="500"
              className="only-desktop event-webinar mt-3"
              src={webinar_link}
              frameBorder="0"
            ></iframe>
            <iframe
              className="only-mobile event-webinar mt-3"
              frameBorder="0"
              width="474px"
              height="302px"
              src={webinar_link}
            ></iframe>
          </>
        )}
        <div
          dangerouslySetInnerHTML={{ __html: content }}
          className="font-16 mt-32"
        ></div>
      </div>

      <Footer />
    </div>
  )
}
// export const query = graphql`
//   {
//     slug
//   }
// `
